<template>
  <div>

        <b-card
            no-body
            style="max-width: 28rem;"
            class="card-3"         
            :img-src="obj.proUrl!==''? obj.proUrl:catImg('event_1080x540.png')" 
            img-alt="Image"
            img-top
        >
            <template #header>
            <h4 class="mb-0">主辦單位資料</h4>
            </template>
            <b-list-group flush>
            <b-list-group-item>主辦單位名稱 : <span v-text="obj.name"></span> </b-list-group-item>
            <b-list-group-item>目前層級 : <span v-text="convertname(obj.type)"></span></b-list-group-item>
            <b-list-group-item>下一階層 : <span v-text="convertname(obj.ntype)"></span></b-list-group-item>
            <b-list-group-item>狀態 : <span v-text="cstate(obj.state)"></span></b-list-group-item>
            <b-list-group-item>主辦單位簡介 : <br/><span v-text="obj.desc"></span></b-list-group-item>
            <b-list-group-item>主辦單位 Email : <span v-text="obj.email"></span></b-list-group-item>
            <b-list-group-item>主辦單位電話號碼 : <span v-text="obj.hphone"></span></b-list-group-item>
            <b-list-group-item>主辦單位手機號碼 : <span v-text="obj.mphone"></span></b-list-group-item>
            <b-list-group-item>主辦單位聯絡地址 : <br/><span v-text="obj.address"></span></b-list-group-item>
            <b-list-group-item>官方網站 : <a v-if="obj.Sinkurl!==''" :href="obj.Sinkurl==''?'#':ensureHttps(obj.Sinkurl)" target="_blank" rel="noopener noreferrer">請參閱網站資訊</a></b-list-group-item>
            <b-list-group-item>Facebook : <a v-if="obj.Finkurl!==''" :href="obj.Finkurl==''?'#':ensureHttps(obj.Finkurl)" target="_blank" rel="noopener noreferrer">請參閱網站資訊</a></b-list-group-item>
            <b-list-group-item>Instagram : <a v-if="obj.Iinkurl!==''" :href="obj.Iinkurl==''?'#':ensureHttps(obj.iinkurl)" target="_blank" rel="noopener noreferrer">請參閱網站資訊</a></b-list-group-item>
            </b-list-group>
            <b-card-footer>&emsp;</b-card-footer>
        </b-card>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: "Orginforject",
  props: {
    obj: Object,
  },
  data() {
    return {     
      xurl: "",
      imgs: {
        width: 0,
        height: 0
      },
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  methods: {

    groupBy(array, groups, valueKey) {
      var map = new Map();
      groups = [].concat(groups);
      return array.reduce((r, o) => {
        groups.reduce((m, k, i, { length }) => {
          var child;
          if (m.has(o[k])) return m.get(o[k]);
          if (i + 1 === length) {
            child = Object.assign(...groups.map(k => ({ [k]: o[k] })), {
              [valueKey]: 0
            });
            r.push(child);
          } else {
            child = new Map();
          }
          m.set(o[k], child);
          return child;
        }, map)[valueKey] += +o[valueKey];
        return r;
      }, []);
    },

    ensureHttps(url) {
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
          return 'https://' + url;
      }
      return url;
    },     

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    convertname(va1){
      let tmp=""
      va1=="org"? tmp="據點/團體組織":"";
      va1=="town"? tmp="局處/鄉鎮公所":"";
      va1=="county"? tmp="縣市政府":"";
      va1=="admin"? tmp="最高權限管理群組":"";
      return tmp
    }, 
    
    cstate(val){
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp=""
      val=="N"? tmp="尚未審核通過":"";
      val=="Y"? tmp="已審核":"";
      return tmp
    },    


  },
  mounted () {
        /*this.themedata = this.mytheme 
        this.category=this.mycategory */
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.classempy{
  padding:0;
}

.remak{
  background-color:	#FBFFFD;
  border-width:1px;
  border-style:dotted;
  border-color:#D0D0D0;
  padding:10px;
}

.hr-dashed-fixed {
    border: 0;
    padding-top: 1px;
    background: repeating-linear-gradient(to right, #a2a9b6 0px, #a2a9b6 4px, transparent 0px, transparent 10px);
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: absolute;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  bottom: 1750px;
  left: 26%;
  right: 0;
}
div#home a:link {
  color: #578cc9;
}
div#home a:visited {
  color: #578cc9;
}
div#home a:hover {
  color: #578cc9;
}
div#home a:active {
  color: #578cc9;
}
.mapcss{
  width: 100%; height: 500px;
  border: 1px solid rgb(178, 178, 182);
  box-sizing: border-box;
}
</style>
<style>
.divcss img{
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
} 
.hrs {width: 250px;height:1px; border-width:0; color: #3D7878; background: repeating-linear-gradient(to right, #a2a9b6 0px, #a2a9b6 4px, transparent 0px, transparent 10px);}
</style>

