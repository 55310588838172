<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col"><Topbar v-if="defaultval.state=='Y'" ref="con1" @inid="inid" /></div>
    </div>
    <div class="row">
      <div class="col"><Bottombar v-if="defaultval.state=='Y'" ref="con2" /></div>
      <!--<div class="col" v-if="defaultval.state=='N'">請確認您的組織,是否已經被審核通過</div>-->
    </div>
    <div v-if="defaultval.state=='N'">
      <a-result  status="warning" title="請確認該組織,是否已經通過審核.">
        <template #extra>
        </template>
      </a-result>
    </div>
  </div>        
</template>
<script>
import { mapState } from "vuex";
import Topbar from "./contant/Check_topbar.vue"
import Bottombar from "./contant/Check_bottomtbar.vue"


export default {
  name: 'Check_orglist',   
  components: {
    Topbar,
    Bottombar,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
    ...mapState("makeorg", ["datas","defaultval"])
  },
  mounted() {

  },
  methods: {
    inid(){
      this.$refs["con2"].inid()
    }
  },
};
</script>